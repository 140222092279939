import css from "./StoreListItem.module.scss";
import BgBlur from "../../UI/BgBlur";

export default function StoreListItem({
  icon,
  title,
  text,
  price,
  bgColor,
  buttonGradient,
}) {
  const buttonGradientStyle = `linear-gradient(to right, ${buttonGradient[0]}, ${buttonGradient[1]}`;

  return (
    <article className={css.listItem} style={{ background: bgColor }}>
      <img src={icon.src} alt={icon.alt} />
      <div className={css.itemDesc}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <button
        className={css.itemBtn}
        style={{ background: buttonGradientStyle }}
      >
        <h3>
          {price}
          <span>₽</span>
        </h3>
        <BgBlur
          blurColor={"rgba(255, 255, 255, 0.3)"}
          rounded={"8px"}
          zIndex={"0"}
        />
      </button>
    </article>
  );
}
