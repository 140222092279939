import css from "./Game.module.scss";
import GameProgress from "../../UI/GameProgress/GameProgress";
import GamePlay from "../../UI/GamePlay/GamePlay";
import GameBtns from "../../UI/GameBtns/GameBtns";
// import createSignature from "../../../libs/utils/createSignature";
import { GameProvider } from "../../../libs/providers/GamePlayProvider";
import React, { useState, useEffect } from "react";

export default function Game(props) {
  const [backGameData, setBackGameData] = useState();

  useEffect(() => {
    const timestamp = Date.now();
    const stringTimestamp = String(timestamp);

    // console.log(stringTimestamp, createSignature(stringTimestamp));

    // prettier-ignore
    // fetch(
    //   "https://brainstorm-api-6faaca-88d452-5-182-87-145.traefik.me/getQuestions",
    //   {
    //     method: "GET",
    //     mode: "no-cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-signature": createSignature(stringTimestamp),
    //       "timestamp": stringTimestamp,
    //     },
    //   }
    // )
    //   .then((response) => {
    //     console.log(response.json());
    //     response.json();
    //   })
    //   .then((data) => setBackGameData(data))
    //   .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const gameData = props.wsMessage;
  console.log(gameData);

  return (
    <GameProvider>
      <section className={css.game}>
        <GameProgress gameData={gameData} />
        <GamePlay gameData={gameData} />
        <p>{backGameData}</p>
        <GameBtns gameData={gameData} />
      </section>
    </GameProvider>
  );
}
