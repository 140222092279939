import css from "./Tasks.module.scss";
import AppTitle from "../../UI/AppTitle";
import TasksList from "../../UI/TasksList/TasksList";
import TasksCircles from "../../../assets/images/Tasks/TasksCircles.webp";

export default function Tasks() {
  return (
    <section className={css.tasks}>
      <img
        className={css.tasksBg}
        src={TasksCircles}
        alt="background circles"
      />
      <div className={css.content}>
        <AppTitle content={"Задания"} />
        <hr />
        <TasksList />
      </div>
    </section>
  );
}
