import css from "./GamePlay.module.scss";
import GameItem from "../../UI/GameItem/GameItem";
import React, { useState, useEffect, useContext } from "react";
import { GameContex } from "../../../libs/Contexts";

export default function GamePlay({ gameData }) {
  const [itemData, setItemData] = useState(
    gameData.answers.map((item) => ({
      ...item,
      status: null,
    })),
  );

  const [isItemSelected, setIsItemSelected] = useState(false);

  function statusChangeEvent(item, index) {
    if (isItemSelected) return; // Prevent further selection

    setItemData((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            status: true,
          };
        }

        return {
          ...item,
          status: false,
        };
      });
    });

    setIsItemSelected(true); // Mark that an item has been selected
  }

  const { setGameState } = useContext(GameContex);

  useEffect(() => {
    setGameState(itemData);
  }, [itemData]);

  return (
    <section className={css.gamePlay}>
      <h2>{gameData.question}</h2>
      <div className={css.gameWrap}>
        {itemData.map((item, index) => {
          return (
            <GameItem
              key={index}
              status={item.status}
              isCorrect={item.isCorrect}
              answerText={item.text}
              onClick={() => statusChangeEvent(item, index)}
            />
          );
        })}
      </div>
    </section>
  );
}
