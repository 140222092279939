import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MenuProvider } from "./libs/providers/MenuStateProvider";
import { TimerProvider } from "./libs/providers/TimerContextProvider";

ReactDOM.createRoot(document.getElementById("root")).render(
  <TimerProvider>
    <MenuProvider>
      <App />
    </MenuProvider>
  </TimerProvider>,
);
