import css from "./GameProgress.module.scss";
import React, { useState, useEffect } from "react";

export default function GameProgress({ gameData }) {
  const totalItems = new Array(gameData.totalQuestions).fill(null);
  function classNameGenerator(index) {
    const classNames = {
      completed: index + 1 < gameData.currentQuestion,
      incompleted: index + 1 > gameData.currentQuestion,
      inProcess: index + 1 === gameData.currentQuestion,
    };

    const className =
      Object.keys(classNames).find((key) => classNames[key]) || "incompleted";

    return className;
  }

  const [gameTimer, setGameTimer] = useState(
    Number(gameData.questionTime) / 1000,
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setGameTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className={css.gameProgress}>
      <nav>
        {totalItems.map((item, index) => {
          return (
            <div key={index} className={css[classNameGenerator(index)]}>
              <h3 className={css.itemNumber}>
                {classNameGenerator(index) === "inProcess" ? index + 1 : null}
              </h3>
            </div>
          );
        })}
      </nav>
      <h1>{gameTimer}</h1>
    </section>
  );
}
