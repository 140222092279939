import css from "./FriendsTitle.module.scss";
import AppTitle from "../AppTitle";

export default function FriendsTitle() {
  return (
    <section className={css.friendsTitle}>
      <div className={css.titleWrap}>
        <AppTitle content={"Приглашай своих друзей!"} />
      </div>
      <h3>Ты и твои друзья получат бонусы!</h3>
    </section>
  );
}
