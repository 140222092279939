import css from "./GameBtns.module.scss";
import Heart from "../../../assets/images/Heart.webp";
import Coin from "../../../assets/images/Coin.webp";
import LeftCircle from "../../../assets/images/Game/LeftCircle.webp";
import RightCircle from "../../../assets/images/Game/RightCircle.webp";
import { useContext } from "react";
import { GameContex } from "../../../libs/Contexts";

export default function GameBtns({ gameData }) {
  const { gameState } = useContext(GameContex);

  function name(params) {
    console.log(gameState);
  }

  return (
    <section className={css.gameBtns}>
      <button className={css.btnWrap}>
        <img src={LeftCircle} alt="background circle" />
        <img className={css.heartBtn} src={Heart} alt="heart button" />
      </button>
      <button className={css.btnWrap} onClick={name}>
        <img src={RightCircle} alt="background circle" />
        <img className={css.coinBtn} src={Coin} alt="coin button" />
      </button>
    </section>
  );
}
