import React, { useState } from "react";
import { GameContex } from "../Contexts";

export const GameProvider = ({ children }) => {
  const [gameState, setGameState] = useState();

  return (
    <GameContex.Provider value={{ gameState, setGameState }}>
      {children}
    </GameContex.Provider>
  );
};
