/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./styles/App.scss";
import { useState, useContext, useEffect, useRef, useMemo } from "react";
import { MenuContext, TimerContext } from "./libs/Contexts.jsx";
import { TwitchPlayer } from "react-twitch-embed";
import Menu from "./components/screens/Menu/Menu.jsx";
import Main from "./components/screens/Main/Main.jsx";
import Profile from "./components/screens/Profile/Profile.jsx";
import Store from "./components/screens/Store/Store.jsx";
import Tasks from "./components/screens/Tasks/Tasks.jsx";
import Friends from "./components/screens/Friends/Friends.jsx";
import Stats from "./components/screens/Stats/Stats.jsx";
import Game from "./components/screens/Game/Game.jsx";

export default function App() {
  const { gameStartTime, setGameStartTime } = useContext(TimerContext);
  const { menuState, setMenuState } = useContext(MenuContext);
  const [isEventStarted, setIsEventStarted] = useState(false);
  const [wsMessage, setWsMessage] = useState(null);
  const [ws, setWs] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isGameVisible, setIsGameVisible] = useState(false);

  const embed = useRef(); // Move useRef to the top level of the component

  useEffect(() => {
    const connectWebSocket = () => {
      const websocket = new WebSocket("wss://oystr.me:8080");

      websocket.onopen = () => {
        console.log("Connected to WebSocket server");
        websocket.send(JSON.stringify({ type: "user" }));
      };

      websocket.onmessage = (event) => {
        const message = JSON.parse(event.data);

        console.log("Received message:", message.type);

        switch (message.type) {
          case "broadcast":
            console.log("Received message:", message.content);
            setWsMessage(message);
            break;
          case "setTimer":
            const date = new Date(message.content);
            setGameStartTime(date);
            break;
          case "startGame":
            setIsEventStarted(true);
            break;
          case "stopGame":
            setIsEventStarted(false);
            setMenuState("main");
            break;
          case "sendQuestion":
            setWsMessage(message);
            setIsGameVisible(true);
            setTimeout(() => {
              setIsGameVisible(false);
            }, Number(message.questionTime));
            break;
          default:
            console.log("Received unknown message type:", message.type);
            break;
        }
      };

      websocket.onclose = () => {
        console.log(
          "Disconnected from WebSocket server, attempting to reconnect...",
        );
        setTimeout(connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
      };

      websocket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      setWs(websocket);
    };

    connectWebSocket();

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const Twitch = useMemo(() => {
    const handleReady = (e) => {
      embed.current = e;
    };

    const unmute = () => {
      if (embed.current) {
        embed.current.setVolume(1.0); // Set volume to 100%
      }
    };

    return (
      <div className="h-full w-full">
        <TwitchPlayer
          channel="mean_ing"
          autoplay={true}
          height="100%"
          width="100%"
          hideControls
          withChat={false}
          muted={false}
          onReady={handleReady}
        />
        <button
          onClick={unmute}
          className="bg-blue-500 text-white absolute bottom-4 right-4 rounded p-2"
        >
          Вкл. Звук
        </button>
      </div>
    );
  }, []); // Empty dependency array ensures this is only created once

  if (isEventStarted) {
    return (
      <div className="relative h-screen w-screen overflow-hidden">
        {isGameVisible && (
          <div className="pointer-events-auto absolute inset-0 z-20">
            <Game wsMessage={wsMessage} />
          </div>
        )}
        <div
          className={`absolute inset-0 z-10 ${
            isOverlayVisible ? "pointer-events-none" : ""
          }`}
        >
          {Twitch}
        </div>
      </div>
    );
  }

  // EXAMPLE SNIPPET:
  function checkIsEventStarted(params) {
    // setIsEventStarted(params);
  }

  switch (menuState) {
    case "main":
      if (isEventStarted) {
        return (
          <div className="relative h-screen w-screen overflow-scroll">
            <Game />
          </div>
        );
      } else {
        return (
          <div className="relative h-screen w-screen overflow-scroll">
            <Menu bgColor={"rgba(255, 255, 255, 0.3)"} fontColor={"#FFFFFF"} />
            <Main checkIsEventStarted={checkIsEventStarted} />
          </div>
        );
      }
    case "profile":
      return (
        <div className="relative h-screen w-screen overflow-scroll">
          <Menu bgColor={"rgba(255, 255, 255, 0.1)"} />
          <Profile />
        </div>
      );
    case "store":
      return (
        <div className="relative h-screen w-screen overflow-scroll">
          <Menu bgColor={"rgba(255, 255, 255, 0.1)"} />
          <Store />
        </div>
      );
    case "tasks":
      return (
        <div className="relative h-screen w-screen overflow-scroll">
          <Menu bgColor={"rgba(255, 255, 255, 0.1)"} />
          <Tasks />
        </div>
      );
    case "stats":
      return (
        <div className="relative h-screen w-screen overflow-scroll">
          <Menu bgColor={"rgba(255, 255, 255, 0.1)"} />
          <Stats />
        </div>
      );
    case "friends":
      return (
        <div className="relative h-screen w-screen overflow-scroll">
          <Menu bgColor={"rgba(255, 255, 255, 0.1)"} />
          <Friends />
        </div>
      );
    default:
      return null;
  }
}
