import css from "./TasksItem.module.scss";
import BrainCoin from "../../../assets/images/BrainCoin.webp";

export default function TasksItem({ icon, title, reward, taskStatus }) {
  function numGap(num) {
    num = num.toString().split("").reverse();

    let index = 3;
    while (index < num.length) {
      num.splice(index, 0, " ");
      index += 4;
    }

    return num.reverse().join("");
  }

  return (
    <article className={css.tasksItem}>
      <div className={css.itemIcon}>
        <img src={icon.src} alt={icon.alt} />
        <hr className={css.bgGradient} />
      </div>
      <div className={css.itemInfo}>
        <h3>{title}</h3>
        <span className={css.coinSum}>
          <img src={BrainCoin} alt="brainStorm logo" />
          <p>+{numGap(reward)}</p>
        </span>
      </div>
      <div className={css.itemBtn}>
        <img src={taskStatus} alt="link icon" />
      </div>
    </article>
  );
}
