import css from "./ProfileHeader.module.scss";
import AppTitle from "../AppTitle";
import ProfileAvatar from "../../../assets/images/Profile/ProfileAvatar.webp";

export default function ProfileHeader() {
  return (
    <section className={css.profileHeader}>
      <AppTitle content={`Привет, ${"Мария"}!`} />
      <div className={css.profileAvatar}>
        <img src={ProfileAvatar} alt="profile avatar" />
      </div>
    </section>
  );
}
