import React, { useState } from "react";
import { TimerContext } from "../Contexts";

export const TimerProvider = ({ children }) => {
  const [gameStartTime, setGameStartTime] = useState(0);

  return (
    <TimerContext.Provider value={{ gameStartTime, setGameStartTime }}>
      {children}
    </TimerContext.Provider>
  );
};
