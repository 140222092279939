export default function BgBlur({ blurColor, rounded, zIndex = "-10" }) {
  return (
    <div
      className="absolute left-0 top-0 h-full w-full backdrop-blur-md"
      style={{
        background: blurColor,
        borderRadius: rounded,
        zIndex: zIndex,
      }}
    ></div>
  );
}
