import css from "./FriendsList.module.scss";
import FriendsListItem from "../FriendsListItem/FriendsListItem";
import ProfileAvatar from "../../../assets/images/Friends/ProfileAvatar.webp";

export default function FriendsList(props) {
  const friendsDataExample = [
    {
      id: 1,
      src: ProfileAvatar,
      alt: "profile avatar",
      nickName: "Mary Jane",
      reward: 146.135,
    },
    {
      id: 2,
      src: null,
      alt: "profile avatar",
      nickName: "Default Profile",
      reward: 789.456,
    },
    {
      id: 3,
      src: null,
      alt: "profile avatar",
      nickName: "Default Profile",
      reward: 132.789,
    },
    {
      id: 4,
      src: ProfileAvatar,
      alt: "profile avatar",
      nickName: "Default Profile",
      reward: 456.123,
    },
    {
      id: 5,
      src: null,
      alt: "profile avatar",
      nickName: "Default Profile",
      reward: 741.852,
    },
    {
      id: 6,
      src: null,
      alt: "profile avatar",
      nickName: "Default Profile",
      reward: 963.741,
    },
  ];

  return (
    <section className={css.friendsList}>
      <h3>Твои друзья</h3>
      <div className={css.listWrap}>
        {friendsDataExample.map((item) => {
          return (
            <FriendsListItem
              key={item.id}
              icon={{ src: item.src, alt: `${item.nickName}'s ${item.alt}` }}
              nickName={item.nickName}
              reward={item.reward}
            />
          );
        })}
      </div>
    </section>
  );
}
