import css from "./MenuSection.module.scss";
export default function MenuSection(props) {
  if (props.isActive) {
    return (
      <article onClick={props.onClick} className={css.menuBtn}>
        <div className={css.iconWrap}>
          <div className={css.colorIcon}>
            <img src={props.src} alt="icon screen" />
            <hr className={css.bgGradient} />
          </div>
        </div>
        <span>{props.text}</span>
      </article>
    );
  } else {
    return (
      <article onClick={props.onClick} className={css.menuBtn}>
        <div className={css.iconWrap}>
          <img src={props.src} alt="icon screen" />
        </div>
        <span className={css.lightText} style={{ color: props.fontColor }}>
          {props.text}
        </span>
      </article>
    );
  }
}
