import css from "./Profile.module.scss";
import ProfileHeader from "../../UI/ProfileHeader/ProfileHeader";
import ProfileStats from "../../UI/ProfileStats/ProfileStats";
import ProfileList from "../../UI/ProfileList/ProfileList";
import ProfileCircles from "../../../assets/images/Profile/ProfileCircles.webp";

export default function Profile() {
  return (
    <section className={css.profile}>
      <img
        className={css.profileBg}
        src={ProfileCircles}
        alt="background circles"
      />
      <div className={css.content}>
        <ProfileHeader />
        <ProfileStats />
        <ProfileList />
      </div>
    </section>
  );
}
