import css from "./ProfileStats.module.scss";

export default function ProfileStats() {
  return (
    <section className={css.profileStats}>
      <div className={css.statsSection}>
        <div className={css.statsProgress}>
          <article className={css.wrapper}>
            <div className={css.outer}>
              <div></div>
            </div>
            <div className={css.inner}></div>
          </article>
        </div>
        <div className={css.statsData}>
          <h2>51.2</h2>
          <p>% ПОБЕД</p>
        </div>
      </div>

      <div className={css.statsSection}>
        <div className={css.statsData}>
          <h2>
            502.211<span>₽</span>
          </h2>
          <p>СУММА ВЫИГРЫША</p>
        </div>
      </div>
    </section>
  );
}
