import css from "./MenuFriends.module.scss";

export default function MenuFriends() {
  return (
    <article className={css.menuFriends}>
      <h2>Пригласить друга</h2>
      <hr className={css.bgGradient} />
    </article>
  );
}
