import css from "./StatsOnline.module.scss";
import Group from "../../../assets/images/Stats/Group.webp";
import YellowCircle from "../../../assets/images/Stats/YellowCircle.webp";
import OrangeCircle from "../../../assets/images/Stats/OrangeCircle.webp";
import BlueCircle from "../../../assets/images/Stats/BlueCircle.webp";

export default function StatsOnline() {
  return (
    <article className={css.headerItem}>
      <div className={css.imgBg}>
        <img src={YellowCircle} alt="" className={css.yellowCircle} />
        <img src={OrangeCircle} alt="" className={css.orangeCircle} />
        <img src={BlueCircle} alt="" className={css.blueCircle} />
      </div>
      <div className={css.onlineItem}>
        <img src={Group} alt="players icon" />
        <div className={css.onlineCount}>
          <hr className={css.greenDot} />
          <h2>50057</h2>
        </div>
        <p>ИГРОКОВ ОНЛАЙН</p>
      </div>
    </article>
  );
}
