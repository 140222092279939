import css from "./Friends.module.scss";
import FriendsTitle from "../../UI/FriendsTitle/FriendsTitle";
import FriendsJoin from "../../UI/FriendsJoin/FriendsJoin";
import FriendsList from "../../UI/FriendsList/FriendsList";
import FriendsCirclesLeftFirst from "../../../assets/images/Friends/LeftFirstCircle.webp";
import FriendsCirclesLeftSecond from "../../../assets/images/Friends/LeftSecondCircle.webp";
import FriendsCirclesRight from "../../../assets/images/Friends/RightCircle.webp";

export default function Friends() {
  return (
    <section className={css.friends}>
      <div className={css.friendsBg}>
        <img
          className={css.leftCircle}
          src={FriendsCirclesLeftFirst}
          alt="background circles"
        />
        <img
          className={css.centerCircle}
          src={FriendsCirclesLeftSecond}
          alt="background circles"
        />
        <img
          className={css.rightCircle}
          src={FriendsCirclesRight}
          alt="background circles"
        />
      </div>
      <div className={css.content}>
        <FriendsTitle />
        <FriendsJoin title={"Пригласи друга!"} bonus={"25 000"} />
        <FriendsList />
      </div>
    </section>
  );
}
