import css from "./ProfileList.module.scss";
import ProfileListItem from "../ProfileListItem/ProfileListItem";

export default function ProfileList() {
  const profileDataExample = [
    {
      id: 1,
      result: true,
      gain: 12.125,
      date: new Date().getTime(),
    },
    {
      id: 2,
      result: false,
      gain: -12.125,
      date: new Date().getTime(),
    },
    {
      id: 3,
      result: true,
      gain: 12.125,
      date: new Date().getTime(),
    },
    {
      id: 4,
      result: false,
      gain: -12.125,
      date: new Date().getTime(),
    },
    {
      id: 5,
      result: true,
      gain: 12.125,
      date: new Date().getTime(),
    },
    {
      id: 6,
      result: false,
      gain: -12.125,
      date: new Date().getTime(),
    },
    {
      id: 7,
      result: true,
      gain: 12.125,
      date: new Date().getTime(),
    },
    {
      id: 8,
      result: false,
      gain: -12.125,
      date: new Date().getTime(),
    },
  ];

  return (
    <section className={css.profileList}>
      <div className={css.listNav}>
        <span>Дата</span>
        <span>Статус</span>
        <span>Результат</span>
      </div>
      <div className={css.listWrap}>
        {profileDataExample.map((item) => {
          return (
            <ProfileListItem
              key={item.id}
              result={item.result}
              gain={item.gain}
              dateInMs={item.date}
            />
          );
        })}
      </div>
    </section>
  );
}
