/* eslint-disable no-unused-vars */
import css from "./MenuStore.module.scss";
import ShopBtn from "../../../assets/images/Menu/Store/StoreBtn.webp";
import { MenuContext } from "../../../libs/Contexts";
import { useContext } from "react";

export default function MenuStore() {
  const { menuState, setMenuState } = useContext(MenuContext);

  return (
    <article
      className={css.storeBtn}
      onClick={() => setMenuState(() => "store")}
    >
      <div className={css.storeImg}>
        <img src={ShopBtn} alt="store logo" />
      </div>
    </article>
  );
}
