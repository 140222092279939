import css from "./GameItem.module.scss";

export default function GameItem({ status, isCorrect, answerText, onClick }) {
  const isNull = status === null || status === undefined;

  if (isNull || status === false) {
    const unselectedOuter = ` ${css.defaultOuter} ${!isNull ? css.unselectedOuter : ""} `;
    const unselectedText = ` ${css.defaultText} ${!isNull ? css.unselectedText : ""} `;

    return (
      <article className={css.defaultItem} onClick={onClick}>
        <div className={unselectedOuter}>
          <div className={css.defaultInner}></div>
        </div>
        <p className={unselectedText}>{answerText}</p>
      </article>
    );
  }

  const selectedClass = isCorrect ? css.correct : css.selectedItem;

  return (
    <article
      className={`${css.defaultItem} ${selectedClass}`}
      onClick={onClick}
    >
      <div className={css.selectedOuter}>
        <hr className={css.bgGradient} />
        <div className={css.selectedInner}>
          <div className={css.innerDot}>
            <hr className={css.bgGradient} />
          </div>
        </div>
      </div>
      <p className={css.defaultText}>{answerText}</p>
    </article>
  );
}
