import css from "./Store.module.scss";
import AppTitle from "../../UI/AppTitle";
import StoreBalance from "../../UI/StoreBalance/StoreBalance";
import StoreList from "../../UI/StoreList/StoreList";
import ProfileCirclesLeft from "../../../assets/images/Store/LeftCircle.webp";
import ProfileCirclesCenter from "../../../assets/images/Store/CenterCircle.webp";
import ProfileCirclesRight from "../../../assets/images/Store/RightCircle.webp";

export default function Store() {
  return (
    <section className={css.store}>
      <div className={css.storeBg}>
        <img
          className={css.leftCircle}
          src={ProfileCirclesLeft}
          alt="background circles"
        />
        <img
          className={css.centerCircle}
          src={ProfileCirclesCenter}
          alt="background circles"
        />
        <img
          className={css.rightCircle}
          src={ProfileCirclesRight}
          alt="background circles"
        />
      </div>
      <div className={css.content}>
        <AppTitle content={"Магазин"} />
        <StoreBalance rub={"502.211"} coin={"502.211"} />
        <StoreList />
      </div>
    </section>
  );
}
