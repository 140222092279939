export default function AppTitle({
  fontSize = "1.75rem",
  lineHeight = "2rem",
  content,
}) {
  return (
    <section className="font-acrom font-bold">
      <h1
        className="text-center"
        style={{ fontSize: fontSize, lineHeight: lineHeight }}
      >
        {content}
      </h1>
    </section>
  );
}
