import css from "./FriendsListItem.module.scss";
import BrainCoin from "../../../assets/images/BrainCoin.webp";
import Profile from "../../../assets/images/Friends/WithoutLogo.webp";

export default function FriendsListItem({ icon, nickName, reward }) {
  return (
    <article className={css.listItem}>
      <div className={css.listItemImg}>
        <div className={css.itemAvatar}>
          <img className={css.withoutLogo} src={Profile} alt="profile icon" />
          <img
            src={icon.src}
            alt={icon.alt}
            style={{ display: icon.src ? "block" : "none" }}
          />
        </div>
        <hr className={css.bgGradient} />
      </div>

      <div className={css.listItemInfo}>
        <h2>{nickName}</h2>
        <hr className={css.dotSpace} />
        <span className={css.coinSum}>
          <img src={BrainCoin} alt="brainStorm logo" />
          <p>{reward}</p>
        </span>
      </div>
    </article>
  );
}
