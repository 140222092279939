import css from "./ProfileListItem.module.scss";
import BrainCoin from "../../../assets/images/BrainCoin.webp";

export default function ProfileListItem({ result, gain, dateInMs }) {
  function actualDate(dateInMs) {
    const date = new Date(dateInMs);
    const result = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };

    Object.keys(result).forEach((key) => {
      if (result[key] < 10) {
        result[key] = "0" + result[key];
      }
      result[key] = result[key].toString();
    });

    return result;
  }

  return (
    <article className={css.listItem}>
      <div className={css.itemDate}>
        <span>
          {actualDate(dateInMs).day}.{actualDate(dateInMs).month},
        </span>
        <span>
          {actualDate(dateInMs).hours}:{actualDate(dateInMs).minutes}
        </span>
      </div>
      <span className={css.itemStats}>
        {result === true ? "победа" : "проигрыш"}
      </span>
      <div className={css.coinStats}>
        <img src={BrainCoin} alt="brainStorm logo" />
        <span
          style={result === true ? { color: "#60DF6C" } : { color: "#E75E3B" }}
        >
          {result === true ? "+" : null}
          {gain}
        </span>
      </div>
    </article>
  );
}
