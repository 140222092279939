import css from "./Stats.module.scss";
import AppTitle from "../../UI/AppTitle";
import StatsOnline from "../../UI/StatsOnline/StatsOnline";
import StatsData from "../../UI/StatsData/StatsData";
import StatsCircles from "../../../assets/images/Stats/StatsCircles.webp";

export default function Stats() {
  return (
    <section className={css.stats}>
      <img
        className={css.statsBg}
        src={StatsCircles}
        alt="background circles"
      />
      <div className={css.content}>
        <div className={css.statsTitle}>
          <AppTitle content={"СТАТИСТИКА"} />
        </div>
        <div className={css.statsData}>
          <StatsOnline />
          <StatsData />
        </div>
      </div>
    </section>
  );
}
