import css from "./MenuStats.module.scss";
import BgBlur from "../../UI/BgBlur";
import BrainCoin from "../../../assets/images/BrainCoin.webp";
import fullHeart from "../../../assets/images/Menu/Main/fullHeart.webp";
import emptyHeart from "../../../assets/images/Menu/Main/emptyHeart.webp";

export default function MenuStats() {
  return (
    <article className={css.menuStats}>
      <div className={css.statsSection}>
        <div className={css.coinLogo}>
          <img src={BrainCoin} alt="BrainStorm logo" />
        </div>
        <h3>12.465</h3>
        <BgBlur blurColor={"rgba(255, 255, 255, 0.3)"} rounded={"6px"} />
      </div>
      <div className={css.statsSection}>
        <h3>Жизни</h3>
        <section className={css.heartBar}>
          <img src={fullHeart} alt="available lives" />
          <img src={fullHeart} alt="available lives" />
          <img src={emptyHeart} alt="wasted lives" />
        </section>
        <BgBlur blurColor={"rgba(255, 255, 255, 0.3)"} rounded={"6px"} />
      </div>
    </article>
  );
}
