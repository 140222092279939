import css from "./StatsData.module.scss";

export default function StatsData() {
  return (
    <section className={css.statsData}>
      <div className={css.statsLine}>
        <article className={[css.statsItem, css.longItem].join(" ")}>
          <div className={css.colorTittle}>
            <h2>
              50.572.211<span>₽</span>
            </h2>
            <div className={css.textBlur}>
              50.572.211<span>₽</span>
            </div>
          </div>
          <p>разыграно за всё время</p>
        </article>
        <article className={[css.statsItem, css.shortItem].join(" ")}>
          <h2>
            50211<span>₽</span>
          </h2>
          <p>СЕГОДНЯ</p>
        </article>
      </div>

      <div className={css.statsLine}>
        <article className={[css.statsItem, css.shortItem].join(" ")}>
          <h2>50211</h2>
          <p>игроков в день</p>
        </article>
        <article className={[css.statsItem, css.longItem].join(" ")}>
          <div className={css.colorTittle}>
            <h2>50.572.211</h2>
            <div className={css.textBlur}>50.572.211</div>
          </div>
          <p>всего игроков</p>
        </article>
      </div>
    </section>
  );
}
