import css from "./StoreList.module.scss";
import StoreListItem from "../../UI/StoreListItem/StoreListItem";
import Coin from "../../../assets/images/Coin.webp";
import Heart from "../../../assets/images/Heart.webp";

export default function StoreList() {
  const storeDataExample = [
    {
      id: 1,
      src: Coin,
      alt: "Иконка жетона “50/50”",
      title: "Жетон “50/50”",
      text: "Я так и не понял что он делает",
      price: 100,
      bgColor: "#71513A",
      buttonGradient: ["#FF2F00", "#FF8800"],
    },
    {
      id: 2,
      src: Heart,
      alt: 'Иконка сердечка "Одна жизнь"',
      title: "Одна жизнь",
      text: "Восполняет 1 ячейку жизни",
      price: 100,
      bgColor: "#734449",
      buttonGradient: ["#FF001D", "#FF2756"],
    },
    {
      id: 3,
      src: Heart,
      alt: 'Иконка сердечка "Одна жизнь"',
      title: "Одна жизнь",
      text: "Восполняет 1 ячейку жизни",
      price: 100,
      bgColor: "#734449",
      buttonGradient: ["#FF001D", "#FF2756"],
    },
    {
      id: 4,
      src: Coin,
      alt: "Иконка жетона “50/50”",
      title: "Жетон “50/50”",
      text: "Я так и не понял что он делает",
      price: 100,
      bgColor: "#71513A",
      buttonGradient: ["#FF2F00", "#FF8800"],
    },
    {
      id: 5,
      src: Coin,
      alt: "Иконка жетона “50/50”",
      title: "Жетон “50/50”",
      text: "Я так и не понял что он делает",
      price: 100,
      bgColor: "#71513A",
      buttonGradient: ["#FF2F00", "#FF8800"],
    },
    {
      id: 6,
      src: Heart,
      alt: 'Иконка сердечка "Одна жизнь"',
      title: "Одна жизнь",
      text: "Восполняет 1 ячейку жизни",
      price: 100,
      bgColor: "#734449",
      buttonGradient: ["#FF001D", "#FF2756"],
    },
  ];

  return (
    <section className={css.storeList}>
      {storeDataExample.map((item) => {
        return (
          <StoreListItem
            key={item.id}
            icon={{ src: item.src, alt: item.alt }}
            title={item.title}
            text={item.text}
            price={item.price}
            bgColor={item.bgColor}
            buttonGradient={item.buttonGradient}
          />
        );
      })}
    </section>
  );
}
