/* eslint-disable react-hooks/exhaustive-deps */
import { TimerContext } from "../../../libs/Contexts";
import css from "./MainTimer.module.scss";
import React, { useState, useContext, useEffect } from "react";

export default function MainTimer({ checkIsEventStarted }) {
  const { gameStartTime, setGameStartTime } = useContext(TimerContext);
  const [timer, setTimer] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const resCalc = timeCalc(gameStartTime);
      setTimer(resCalc);

      if (
        resCalc.hours === "00" &&
        resCalc.minutes === "00" &&
        resCalc.seconds === "00"
      ) {
        checkIsEventStarted(true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [gameStartTime]);

  function timeCalc(endTime) {
    const currentDate = new Date();
    const difference = endTime - currentDate;

    let seconds = Math.floor((difference / 1000) % 60);
    let minutes = Math.floor((difference / (1000 * 60)) % 60);
    let hours = Math.floor(difference / (1000 * 60 * 60));

    if (difference < 0) {
      seconds = 0;
      minutes = 0;
      hours = 0;
    }

    return {
      hours: hours < 10 ? "0" + hours : hours,
      minutes: minutes < 10 ? "0" + minutes : minutes,
      seconds: seconds < 10 ? "0" + seconds : seconds,
    };
  }

  return (
    <section className={css.mainTimer}>
      <div className={css.timerCol}>
        <div className={css.timerNums}>{timer.hours}</div>
        <span className={css.timerTitle}>Часов</span>
      </div>
      <div className={css.timerCol}>
        <div className={css.timerNums}>{timer.minutes}</div>
        <span className={css.timerTitle}>Минут</span>
      </div>
      <div className={css.timerCol}>
        <div className={css.timerNums}>{timer.seconds}</div>
        <span className={css.timerTitle}>Секунд</span>
      </div>
    </section>
  );
}
