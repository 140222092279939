import css from "./FriendsJoin.module.scss";
import Gift from "../../../assets/images/Friends/Gift.webp";
import BrainCoin from "../../../assets/images/BrainCoin.webp";

export default function FriendsJoin(props) {
  return (
    <section className={css.friendsJoin}>
      <img className={css.joinLogo} src={Gift} alt="join icon" />
      <div className={css.joinInfo}>
        <h2>{props.title}</h2>
        <span className={css.coinSum}>
          <img src={BrainCoin} alt="brainStorm logo" />
          <p>+{props.bonus}</p>
        </span>
      </div>
    </section>
  );
}
