import React, { useState } from "react";
import { MenuContext } from "../Contexts";

export const MenuProvider = ({ children }) => {
  const [menuState, setMenuState] = useState("main");

  return (
    <MenuContext.Provider value={{ menuState, setMenuState }}>
      {children}
    </MenuContext.Provider>
  );
};
