import css from "./TasksList.module.scss";
import TasksItem from "../../UI/TasksItem/TasksItem";
import ArrowRight from "../../../assets/images/Tasks/ArrowRight.webp";
import CheckCircle from "../../../assets/images/Tasks/CheckCircle.webp";
import GroupPeople from "../../../assets/images/Tasks/GroupPeople.webp";
import Facebook from "../../../assets/images/Tasks/Facebook.webp";
import Telegram from "../../../assets/images/Tasks/Telegram.webp";
import Twitter from "../../../assets/images/Tasks/Twitter.webp";
import YouTube from "../../../assets/images/Tasks/YouTube.webp";

export default function TasksList(props) {
  const tasksDataExample = [
    {
      id: 1,
      src: YouTube,
      alt: "Иконка YouTube",
      title: "Подпишись на наш YouTube",
      reward: 6669000,
      taskStatus: true,
    },
    {
      id: 2,
      src: GroupPeople,
      alt: "Иконка GroupPeople",
      title: "Пригласи 3 друзей",
      reward: 7894561,
      taskStatus: true,
    },
    {
      id: 3,
      src: Telegram,
      alt: "Иконка Telegram",
      title: "Подпишись на наш TG канал",
      reward: 2378945,
      taskStatus: false,
    },
    {
      id: 4,
      src: Telegram,
      alt: "Иконка Telegram",
      title: "Вступи в наш TG Чат",
      reward: 6123789,
      taskStatus: true,
    },
    {
      id: 5,
      src: Twitter,
      alt: "Иконка Twitter",
      title: "Подпишись на наш X",
      reward: 4561237,
      taskStatus: false,
    },
    {
      id: 6,
      src: Facebook,
      alt: "Иконка Facebook",
      title: "Подпишись на наш Facebook",
      reward: 8945612,
      taskStatus: false,
    },
  ];

  return (
    <section className={css.tasksList}>
      {tasksDataExample.map((item) => {
        return (
          <TasksItem
            key={item.id}
            icon={{ src: item.src, alt: item.alt }}
            title={item.title}
            reward={item.reward}
            taskStatus={item.taskStatus === true ? CheckCircle : ArrowRight}
          />
        );
      })}
    </section>
  );
}
