import css from "./StoreBalance.module.scss";
import BgBlur from "../../UI/BgBlur";
import BrainCoin from "../../../assets/images/BrainCoin.webp";

export default function StoreBalance(props) {
  return (
    <section className={css.storeBalance}>
      <div className={css.balanceSection}>
        <h2 className={css.balanceTitle}>
          {props.rub}
          <span>₽</span>
        </h2>
        <p>Баланс в рублях</p>
        <BgBlur blurColor={"rgba(255, 255, 255, 0.2)"} rounded={"10px"} />
      </div>

      <div className={css.balanceSection}>
        <h2 className={css.balanceTitle}>
          {props.coin}
          <img src={BrainCoin} alt="brainStorm logo" />
        </h2>
        <p>Баланс в валюте</p>
        <BgBlur blurColor={"rgba(255, 255, 255, 0.2)"} rounded={"10px"} />
      </div>
    </section>
  );
}
