import css from "./Main.module.scss";
import AppTitle from "../../UI/AppTitle.jsx";
import MainTimer from "./../../UI/MainTimer/MainTimer.jsx";
import MainCircles from "../../../assets/images/Main/MainCircles.webp";

export default function Main(props) {
  return (
    <section className={css.main}>
      <img className={css.mainBg} src={MainCircles} alt="background circles" />
      <div className={css.content}>
        <AppTitle
          fontSize={"2rem"}
          lineHeight={"2.375rem"}
          content={"BrainStorm"}
        />
        <hr />
        <MainTimer checkIsEventStarted={props.checkIsEventStarted} />
      </div>
    </section>
  );
}
