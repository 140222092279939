/* eslint-disable no-unused-vars */
import css from "./Menu.module.scss";
import BgBlur from "../../UI/BgBlur";
import MenuSection from "../../UI/MenuSection/MenuSection";
import MenuStats from "../../UI/MenuStats/MenuStats";
import MenuStore from "../../UI/MenuStore/MenuStore";
import MenuFriends from "../../UI/MenuFriends/MenuFriends";
import Profile from "../../../assets/images/Menu/Section/Profile.webp";
import Friends from "../../../assets/images/Menu/Section/Friends.webp";
import Tasks from "../../../assets/images/Menu/Section/Tasks.webp";
import Info from "../../../assets/images/Menu/Section/Info.webp";
import ActiveProfile from "../../../assets/images/Menu/ActiveSection/ActiveProfile.webp";
import ActiveFriends from "../../../assets/images/Menu/ActiveSection/ActiveFriends.webp";
import ActiveTasks from "../../../assets/images/Menu/ActiveSection/ActiveTasks.webp";
import ActiveInfo from "../../../assets/images/Menu/ActiveSection/ActiveInfo.webp";
import { MenuContext } from "../../../libs/Contexts";
import { useContext } from "react";

export default function Menu({ bgColor, fontColor }) {
  const { menuState, setMenuState } = useContext(MenuContext);

  const menuItems = [
    {
      id: "profile",
      text: "Профиль",
      imageSrc: Profile,
      activeImageSrc: ActiveProfile,
    },
    {
      id: "friends",
      text: "Друзья",
      imageSrc: Friends,
      activeImageSrc: ActiveFriends,
    },
    {
      id: "tasks",
      text: "Задания",
      imageSrc: Tasks,
      activeImageSrc: ActiveTasks,
    },
    {
      id: "stats",
      text: "Инфо",
      imageSrc: Info,
      activeImageSrc: ActiveInfo,
    },
  ];

  function menuComponent(menuState) {
    switch (menuState) {
      case "profile":
        return <MenuStore />;
      case "friends":
        return <MenuFriends />;
      case "main":
        return <MenuStats />;
      default:
        return null;
    }
  }

  function handleClick(menuState, itemId) {
    if (menuState === itemId) {
      setMenuState(() => "main");
    } else {
      setMenuState(() => itemId);
    }
  }

  return (
    <nav className={css.menu}>
      {menuComponent(menuState)}
      <section className={css.menuBar}>
        {menuItems.map((item) => {
          return (
            <MenuSection
              key={item.id}
              onClick={() => handleClick(menuState, item.id)}
              src={menuState === item.id ? item.activeImageSrc : item.imageSrc}
              isActive={menuState === item.id}
              text={item.text}
              fontColor={fontColor}
            />
          );
        })}
        <BgBlur blurColor={bgColor} rounded={"10px"} />
      </section>
    </nav>
  );
}
